import { makeAutoObservable } from 'mobx';
import React from 'react';
import { RuleKind } from '~/components/PolicyCard/general';
import {
  EgressTooltip,
  IngressTooltip,
} from '~/components/PolicyCard/Tooltips';
import { CardKind, CardSide } from '~/domain/cimulator/types';
import { PolicyStore } from '~/store/stores/policy';
import { RatingPoints } from '~/store/stores/policy/types';
import { AssistantEmitterActions, emitter } from '../emitter';
import {
  createReactiveValue,
  PolicyAssistantAction,
  PolicyAssistantActionKind,
  PolicyAssistantEntry,
  PolicyAssistantTutorial,
} from '../general';

import clusterImage from './main-assets/cluster.png';
import ingressEgressImage from './main-assets/ingress-egress.png';
import kubeDnsImage from './main-assets/kube-dns.png';
import sameNamespaceImage from './main-assets/same-namespace.png';
import ingressFromOutsideImage from './main-assets/ingress-from-outside.png';
import egressToOutsideImage from './main-assets/egress-to-outside.png';
import crossNamespaceImage from './main-assets/cross-namespace.png';

export class PolicyAssistantMainTutorial implements PolicyAssistantTutorial {
  public readonly id = 'main';
  public readonly index = -1;
  public readonly title = 'Main tutorial';

  private _policy: PolicyStore;
  private _entries: Set<PolicyAssistantEntry> = new Set();

  constructor(policy: PolicyStore) {
    makeAutoObservable(this);

    this._policy = policy;

    this.init();
  }

  get entries() {
    return Array.from(this._entries);
  }

  private addEntries = (...entries: PolicyAssistantEntry[]) => {
    entries.forEach(entry => {
      this._entries.add(entry);
    });
  };

  private init = () => {
    this.addEntries(...this.createWelcome());
    this.addEntries(this.createPolicyNaming());
    this.addEntries(this.createEnableDefaultDeny());
    this.addEntries(this.createKubeDns());
    this.addEntries(...this.createAllowTrafficInTheSameNamespace());
    this.addEntries(...this.createAllowIngressOutside());
    this.addEntries(...this.createAllowEgressOutside());
    this.addEntries(...this.createAllowCrossNamespace());
    this.addEntries(this.createAllowNetworkBasedLivenessReadinessProbes());

    this.addEntries(...this.tryItOut());
  };

  private createWelcome = () => {
    const entry1 = new PolicyAssistantEntry({
      id: 'welcome',
      title: <h2>Welcome to the Network Policy Editor!</h2>,
      description: (
        <div>
          <p>
            This tutorial will teach you how to create a network policy using
            the Editor. It explains basic network policy concepts and guides you
            through the steps needed to achieve the desired least-privilege
            security and zero-trust concepts.
          </p>
        </div>
      ),
    });
    return [entry1];
  };

  private createPolicyNaming = () => {
    const entry = new PolicyAssistantEntry({
      id: 'pod-selector',
      title: <h2>Step 1. What pods do you want to secure?</h2>,
      description: (
        <div>
          <p>
            <img src={clusterImage} style={{ maxWidth: '400px' }} />
          </p>
          <p>
            First, select the pods to which the policy should be applied by
            matching pod labels. A network policy can be applied to an
            individual pod, a group of pods, an entire namespace, or an entire
            cluster.<sup>1</sup> If the pod selector is left empty, the policy
            will be applied to all the pods in a namespace.
          </p>
          <p>
            <small>
              <sup>1</sup>{' '}
              <a
                href="https://docs.cilium.io/en/stable/policy/kubernetes/#clusterwide-policies"
                target="_blank"
                rel="noreferrer"
              >
                Clusterwide Policies
              </a>{' '}
              are not yet implemented in the Editor.
            </small>
          </p>
        </div>
      ),
    });

    entry.addAction(
      new PolicyAssistantAction({
        id: 'specify-pod-selector',
        kind: PolicyAssistantActionKind.Button,
        title: 'Specify the pod selector, policy name and namespace',
        description: null,
        buttonText: 'Specify',
        props: { intent: 'primary' },
        rating: null,
        active: createReactiveValue(() => false),
        disabled: createReactiveValue(() => false),
        onChange: () => {
          emitter.emit(
            AssistantEmitterActions.OpenCardCrudPopup,
            CardSide.Selector,
            CardKind.InNamespace,
          );
        },
      }),
    );

    return entry;
  };

  private createEnableDefaultDeny = () => {
    const entry = new PolicyAssistantEntry({
      id: 'default-deny',
      title: (
        <h2>Step 2: Introduce an ingress and egress default deny posture.</h2>
      ),
      description: (
        <div>
          <p>
            <img src={ingressEgressImage} style={{ maxWidth: '318px' }} />
          </p>
          <p>
            The standard network security posture of Kubernetes allows all
            network traffic. You are responsible for restricting all
            communication by introducing a “default deny” posture and then
            allowing only the desired network communication. This can be done
            incrementally — that is, you can place individual pods, groups of
            pods, or entire namespaces into a default deny posture. Or you can
            tackle ingress (traffic into the pod) and egress (traffic out of a
            pod) communication separately.
          </p>
          <p>
            An empty policy with only a pod selector does not enforce any rules.
            By adding a rule (even an empty rule), the selected pods will change
            into a default deny posture for the selected traffic direction.
            Decide if you want to set ingress, egress, or both to a default deny
            posture by adding an empty rule.
          </p>
          <p>
            <strong>
              For this tutorial, enable default deny for both ingress and
              egress.
            </strong>
          </p>
        </div>
      ),
    });

    entry.addAction(
      new PolicyAssistantAction({
        id: 'change-ingress-default-deny',
        kind: PolicyAssistantActionKind.Checkbox,
        title: (
          <span>
            Enable default deny for <b>ingress</b>
          </span>
        ),
        description: null,
        buttonText: 'Allow',
        rating: RatingPoints['Ingress default deny is enabled'],
        active: createReactiveValue(() => this._policy.hasIngressRules),
        disabled: createReactiveValue(() => false),
        onChange: () => {
          emitter.emit(AssistantEmitterActions.ToggleDefaultDenyIngress);
        },
      }),
    );

    entry.addAction(
      new PolicyAssistantAction({
        id: 'change-egress-default-deny',
        kind: PolicyAssistantActionKind.Checkbox,
        title: (
          <>
            Enable default deny for <b>egress</b>
          </>
        ),
        description: null,
        buttonText: 'Allow',
        rating: RatingPoints['Egress default deny is enabled'],
        active: createReactiveValue(() => this._policy.hasEgressRules),
        disabled: createReactiveValue(() => false),
        onChange: () => {
          emitter.emit(AssistantEmitterActions.ToggleDefaultDenyEgress);
        },
      }),
    );

    return entry;
  };

  private createAllowTrafficInTheSameNamespace = () => {
    const entry1 = new PolicyAssistantEntry({
      id: 'allow-traffic-in-the-same-namespace',
      title: <h2>Step 4. Allow Traffic in the Same Namespace</h2>,
      description: (
        <div>
          <p>
            <img src={sameNamespaceImage} style={{ maxWidth: '315px' }} />
          </p>
          <p>
            Decide if the pods chosen by the pod selector will communicate with
            other pods in the same namespace.
          </p>
          <p>
            Note: To allow traffic between two pods, the egress side of the
            sending pod and the ingress side of the receiving pod must both
            allow traffic. This ensures compliance with zero-trust principles by
            allowing each pod to enforce its own policy instead of relying on
            the policies of other pods.
          </p>
        </div>
      ),
    });

    entry1.addAction(
      new PolicyAssistantAction({
        id: 'allow-all-traffic-in-the-same-namespace',
        kind: PolicyAssistantActionKind.Button,
        title: (
          <span>
            Allow <b>all traffic</b> in the same namespace
          </span>
        ),
        description: null,
        buttonText: 'Allow',
        rating:
          (RatingPoints['Allow within namespace for ingress'] +
            RatingPoints['Allow within namespace for egress']) /
          2,
        active: createReactiveValue(
          () =>
            this._policy.hasFullIngressInNamespace &&
            this._policy.hasFullEgressInNamespace,
        ),
        disabled: createReactiveValue(() => false),
        onChange: () => {
          this._policy.allowFullInNamespaceIngress();
          this._policy.allowFullInNamespaceEgress();
        },
      }),
    );

    entry1.addAction(
      new PolicyAssistantAction({
        id: 'allow-ingress-traffic-from-pods-in-the-same-namespace',
        kind: PolicyAssistantActionKind.Button,
        title: (
          <span>
            Allow <b>ingress traffic</b> from specific pods in the same
            namespace
          </span>
        ),
        description: null,
        buttonText: 'Allow',
        rating:
          RatingPoints[
          'Allow within namespace for pod selector and no rule to allow within namespace for ingress'
          ],
        active: createReactiveValue(
          () => this._policy.hasSomeIngressSelectorsInNamespace,
        ),
        disabled: createReactiveValue(() => false),
        onChange: () => {
          emitter.emit(
            AssistantEmitterActions.OpenCardCrudPopup,
            CardSide.Ingress,
            CardKind.InNamespace,
            RuleKind.PodSelector,
          );
        },
      }),
    );

    entry1.addAction(
      new PolicyAssistantAction({
        id: 'allow-egress-traffic-from-pods-in-the-same-namespace',
        kind: PolicyAssistantActionKind.Button,
        title: (
          <span>
            Allow <b>egress traffic</b> to specific pods in the same namespace
          </span>
        ),
        description: null,
        buttonText: 'Allow',
        rating:
          RatingPoints[
          'Allow within namespace for pod selector and no rule to allow within namespace for egress'
          ],
        active: createReactiveValue(
          () => this._policy.hasSomeEgressSelectorsInNamespace,
        ),
        disabled: createReactiveValue(() => false),
        onChange: () => {
          emitter.emit(
            AssistantEmitterActions.OpenCardCrudPopup,
            CardSide.Egress,
            CardKind.InNamespace,
            RuleKind.PodSelector,
          );
        },
      }),
    );

    return [entry1];
  };

  private createAllowIngressOutside = () => {
    const entry1 = new PolicyAssistantEntry({
      id: 'allow-incoming-traffic-from-outside',
      title: <h2>Step 5. Allow Incoming Traffic from Outside the Cluster</h2>,
      description: (
        <div>
          <p>
            <img src={ingressFromOutsideImage} style={{ maxWidth: '285px' }} />
          </p>
          <p>
            All traffic from outside the cluster is currently dropped. Decide if
            the isolated pods should receive ingress traffic from outside the
            cluster.
          </p>
        </div>
      ),
    });

    entry1.addAction(
      new PolicyAssistantAction({
        id: 'allow-ingress-traffic-from-outside-the-cluster',
        kind: PolicyAssistantActionKind.Button,
        title: (
          <span>
            Allow <b>ingress traffic</b> from outside the cluster
          </span>
        ),
        description: null,
        buttonText: 'Allow',
        rating: RatingPoints['Ingress outside cluster'],
        active: createReactiveValue(() => this._policy.hasIngressFromOutside),
        disabled: createReactiveValue(() => false),
        onChange: () => {
          emitter.emit(
            AssistantEmitterActions.OpenCardCrudPopup,
            CardSide.Ingress,
            CardKind.OutsideCluster,
            RuleKind.All,
          );
        },
      }),
    );

    return [entry1];
  };

  private createAllowEgressOutside = () => {
    const entry1 = new PolicyAssistantEntry({
      id: 'allow-outgoing-traffic-from-the-cluster',
      title: <h2>Step 6. Allow Outgoing Traffic from the Cluster</h2>,
      description: (
        <div>
          <p>
            <img src={egressToOutsideImage} style={{ maxWidth: '355px' }} />
          </p>
          <p>
            All traffic to endpoints outside of the cluster is currently
            dropped. You can allow egress traffic to the entire Internet;
            however, we recommend allowing traffic only to known DNS names,
            known CIDRs, or a specific port, like port 80 or 443, at the least.
          </p>
        </div>
      ),
    });

    entry1.addAction(
      new PolicyAssistantAction({
        id: 'allow-egress-outside-the-cluster-to-certain-ports',
        kind: PolicyAssistantActionKind.Button,
        title: (
          <span>
            Allow <b>egress</b> outside the cluster to certain ports
          </span>
        ),
        description: null,
        buttonText: 'Allow',
        rating: RatingPoints['Egress outside cluster to specific ports'],
        active: createReactiveValue(() => {
          return this._policy.hasEgressOutsideClusterToSpecificPorts;
        }),
        disabled: createReactiveValue(() => false),
        onChange: action => {
          emitter.emit(
            AssistantEmitterActions.OpenCardCrudPopup,
            CardSide.Egress,
            CardKind.OutsideCluster,
            RuleKind.All,
          );
        },
      }),
    );

    entry1.addAction(
      new PolicyAssistantAction({
        id: 'allow-egress-outside-the-cluster-to-a-dns-name',
        kind: PolicyAssistantActionKind.Button,
        title: (
          <span>
            Allow <b>egress</b> outside the cluster to a DNS name
          </span>
        ),
        description: null,
        buttonText: 'Allow',
        rating: RatingPoints['Egress outside cluster to FQDN'],
        active: createReactiveValue(() => {
          return this._policy.hasEgressFqdnOutsideCluster;
        }),
        disabled: createReactiveValue(() => false),
        onChange: action => {
          emitter.emit(
            AssistantEmitterActions.OpenCardCrudPopup,
            CardSide.Egress,
            CardKind.OutsideCluster,
            RuleKind.Fqdn,
          );
        },
      }),
    );

    entry1.addAction(
      new PolicyAssistantAction({
        id: 'allow-egress-outside-the-cluster-to-a-cidr',
        kind: PolicyAssistantActionKind.Button,
        title: (
          <span>
            Allow <b>egress</b> outside the cluster to a CIDR
          </span>
        ),
        description: null,
        buttonText: 'Allow',
        rating: RatingPoints['Egress outside cluster to CIDR'],
        active: createReactiveValue(
          () => this._policy.hasEgressCidrOutsideCluster,
        ),
        disabled: createReactiveValue(() => false),
        onChange: () => {
          emitter.emit(
            AssistantEmitterActions.OpenCardCrudPopup,
            CardSide.Egress,
            CardKind.OutsideCluster,
            RuleKind.Cidr,
          );
        },
      }),
    );

    return [entry1];
  };

  private createAllowCrossNamespace = () => {
    const entry1 = new PolicyAssistantEntry({
      id: 'allow-cross-namespace-traffic',
      title: <h2>Step 7. Allow Cross-Namespace Traffic</h2>,
      description: (
        <div>
          <p>
            <img src={crossNamespaceImage} style={{ maxWidth: '329px' }} />
          </p>
          <p>
            Think about cross-namespace communications. Even if you allowed all
            ingress or egress from/to the entire Internet, the rules would not
            match the ingress or egress traffic coming from pods in other
            namespaces. If isolated pods might receive (ingress) or send
            (egress) traffic to pods in other namespaces, consider adding rules
            accordingly.
          </p>
        </div>
      ),
    });

    entry1.addAction(
      new PolicyAssistantAction({
        id: 'allow-ingress-traffic-from-pods-in-other-namespaces',
        kind: PolicyAssistantActionKind.Button,
        title: (
          <span>
            Allow <b>ingress</b> from certain pods in other namespaces
          </span>
        ),
        description: null,
        buttonText: 'Allow',
        rating:
          RatingPoints[
          'Allow within namespace for pod selector and no rule to allow within namespace for ingress'
          ],
        active: createReactiveValue(
          () => this._policy.hasSomeIngressSelectorsInCluster,
        ),
        disabled: createReactiveValue(() => false),
        onChange: () => {
          emitter.emit(
            AssistantEmitterActions.OpenCardCrudPopup,
            CardSide.Ingress,
            CardKind.InCluster,
            RuleKind.PodSelector,
          );
        },
      }),
    );

    entry1.addAction(
      new PolicyAssistantAction({
        id: 'allow-egress-traffic-to-pods-in-other-namespaces',
        kind: PolicyAssistantActionKind.Button,
        title: (
          <span>
            Allow <b>egress</b> to certain pods in other namespaces
          </span>
        ),
        description: null,
        buttonText: 'Allow',
        rating:
          RatingPoints[
          'Allow within namespace for pod selector and no rule to allow within namespace for egress'
          ],
        active: createReactiveValue(
          () => this._policy.hasSomeEgressSelectorsInCluster,
        ),
        disabled: createReactiveValue(() => false),
        onChange: () => {
          emitter.emit(
            AssistantEmitterActions.OpenCardCrudPopup,
            CardSide.Egress,
            CardKind.InCluster,
            RuleKind.PodSelector,
          );
        },
      }),
    );

    return [entry1];
  };

  // private createUploadFlows = () => {
  //   const entry1 = new PolicyAssistantEntry({
  //     id: 'upload-flows',
  //     title: <h2>Step 9. Generate Rules With Flows (beta)</h2>,
  //     description: (
  //       <div>
  //         <p>
  //           Network Policy Editor can use Cilium Hubble flows data to generate cross-namespace and egress to outside cluster rules. Try
  //         </p>
  //       </div>
  //     ),
  //   });

  //   entry1.addAction(
  //     new PolicyAssistantAction({
  //       id: 'upload-flows',
  //       kind: PolicyAssistantActionKind.Button,
  //       title: <>Upload Flows</>,
  //       description: null,
  //       rating:
  //         RatingPoints[
  //           'Allow within namespace for pod selector and no rule to allow within namespace for ingress'
  //         ],
  //       active: createReactiveValue(
  //         () => this._policy.hasSomeIngressSelectorsInNamespace,
  //       ),
  //       disabled: createReactiveValue(() => false),
  //       onChange: () => {
  //         emitter.emit(AssistantEmitterActions.OpenHubblePanel);
  //       },
  //     }),
  //   );

  //   return [entry1];
  // };

  private tryItOut = () => {
    const entry1 = new PolicyAssistantEntry({
      id: 'try-it-out',
      title: <h2>Final: Download Resulting Policy</h2>,
      description: (
        <div>
          <p>
            Congratulations! If you followed the tutorial, you now have a policy
            that you can enforce. To use network policies in Kubernetes, you
            must use a networking plugin that implements network policy
            specification; without it, the network policy will have no effect.
            One possible solution is{' '}
            <a href="https://cilium.io/" target="_blank" rel="noreferrer">
              Cilium
            </a>
            . Continue by clicking the Download button and following the
            instructions.
          </p>
          <p>
            Another hands-on way to learn about Cilium network policies by using the {' '}
            <a href="https://isovalent.com/labs/cilium-getting-started/?journey=cloud-network" target="_blank" rel="noreferrer">
              interactive labs
            </a> from Isovalent.
          </p>
        </div>
      ),
    });
    return [entry1];
  };

  private createAllowEgress = () => {
    const entry1 = new PolicyAssistantEntry({
      id: 'allow-egress-traffic-to-pods-within-namespace',
      title: <h2>Step 5. Allowing Egress Traffic To Pods Within Namespace</h2>,
      description: (
        <div>
          <p>
            Once done with <IngressTooltip />, consider doing same for{' '}
            <EgressTooltip /> traffic. Because we have <EgressTooltip /> in
            default deny, traffic leaving pod will be dropped, even if{' '}
            <IngressTooltip />
            is allowed. Allow all egress traffic within namespace or to only
            specific subset of pods.
          </p>
        </div>
      ),
    });

    entry1.addAction(
      new PolicyAssistantAction({
        id: 'allow-only-egress-to-pod-selector',
        kind: PolicyAssistantActionKind.Button,
        title: <span>Allow only egress to pod selector</span>,
        description: null,
        buttonText: 'Allow',
        rating:
          RatingPoints[
          'Allow within namespace for pod selector and no rule to allow within namespace for egress'
          ],
        active: createReactiveValue(
          () => this._policy.hasSomeEgressSelectorsInNamespace,
        ),
        disabled: createReactiveValue(() => false),
        onChange: () => {
          emitter.emit(
            AssistantEmitterActions.OpenCardCrudPopup,
            CardSide.Egress,
            CardKind.InNamespace,
            RuleKind.PodSelector,
          );
        },
      }),
    );

    entry1.addAction(
      new PolicyAssistantAction({
        id: 'allow-all-egress-within-namespace',
        kind: PolicyAssistantActionKind.Button,
        title: <span>Allow all egress within namespace</span>,
        description: null,
        buttonText: 'Allow',
        rating: RatingPoints['Allow within namespace for egress'],
        active: createReactiveValue(() => {
          return (
            this._policy.hasFullEgressInNamespace ||
            this._policy.hasFullEgressInCluster
          );
        }),
        disabled: createReactiveValue(
          () => this._policy.hasFullEgressInCluster,
        ),
        onChange: action => {
          action.active.get()
            ? this._policy.denyFullInNamespaceEgress()
            : this._policy.allowFullInNamespaceEgress();
        },
      }),
    );

    return [entry1];
  };

  private createKubeDns = () => {
    const entry = new PolicyAssistantEntry({
      id: 'allow-kube-dns',
      title: <h2>Step 3. Allow Traffic to Kubernetes DNS</h2>,
      description: (
        <div>
          <p>
            <img src={kubeDnsImage} style={{ maxWidth: '323px' }} />
          </p>
          <p>
            The pods will likely want to resolve DNS queries on port 53/UDP.
            Allow communication to Kubernetes DNS. Specifically, you should
            explicitly allow egress traffic to port 53/UDP only to kube-dns pods
            in the cluster, rather than allowing it to any endpoint on the
            Internet.
          </p>
          <p>
            Advanced: When using Cilium Network Policies, you can enable
            DNS-proxy to observe and filter all DNS egress traffic for the
            selected pods.
          </p>
        </div>
      ),
    });

    entry.addAction(
      new PolicyAssistantAction({
        id: 'allow-egress-to-kube-dns',
        title: 'Allow egress traffic to Kubernetes DNS',
        kind: PolicyAssistantActionKind.Checkbox,
        description: null,
        buttonText: 'Allow',
        rating: null,
        active: createReactiveValue(() => {
          return this._policy.hasEgressRules && this._policy.isKubeDnsAllowed;
        }),
        disabled: createReactiveValue(() => false),
        onChange: action => {
          action.active.get()
            ? this._policy.denyKubeDns()
            : this._policy.allowKubeDns();
        },
      }),
    );

    return entry;
  };

  private createAllowNetworkBasedLivenessReadinessProbes = () => {
    const entry = new PolicyAssistantEntry({
      id: 'allow-network-based-liveness-readiness-probes',
      title: <h2>Step 8. Allow Network-Based Liveness/Readiness Probes</h2>,
      description: (
        <div>
          <p>
            If your pod uses network-based liveness or readiness probes, then
            these probes happen via the network. Kubernetes requires that all
            NetworkPolicy implementations automatically allow traffic from the
            host to the pod.
          </p>
          <p>
            Advanced: Automatically allowing traffic from the host to the pod is
            not ideal based on the principles of least-privilege and zero-trust.
            Click here for more information on configuring Cilium so that
            traffic to the host relies instead on explicit policy to define the
            security posture.
          </p>
        </div>
      ),
    });

    return entry;
  };
}
